.available-wifi-network {
  padding-top: 20px;
}

.light-font-color {
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
}

.wifi-input {
  width: 52%;
}

.wifi-body {
  max-height: 320px;
}

.list-view-padding {
  padding-right: 10px;
}


.etherner-input {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70%;

}

.etherner-input img {
  position: absolute;
  top: 12px;
  right: 9px;
  width: 25px;
}

.etherner-input .error {
  color: #FF4040;
  margin-top: 8px;
}

.sa-input {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 0 30px;
  outline: none;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  height: 50px;
}

.sa-input:focus {
  border: 1px solid #0A84FF;
  outline: none;
}

.error-input .sa-input {
  border: 1px solid #FF4040;
}

.error-input .sa-input:focus,
.error-input.sa-input:focus-visible {
  border: 1px solid #FF4040 !important;
  outline: none;
}


.table-scroll {
  height: calc(100vh - 300px);
  overflow-y: auto;
  scrollbar-width: thin;
}


.table-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.table-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: #0A84FF;
}

.table>thead>tr>th {
  color: rgba(255, 255, 255, 0.5);
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
  background-color: #000;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  border-top: none;
  border-bottom: 0px solid #ddd;
  padding: 10px;
  vertical-align: middle;
}

.table-hover>tbody>tr:hover {
  background-color: #1e1e1e;
}

.table-hover>tbody>tr:hover {
  background-color: #000000;
}

.table-hover>tbody>tr:hover td {
  background-color: #1e1e1e;
}

.table-hover>tbody>tr.active td {
  background-color: #1e1e1e;
  color: rgba(255, 255, 255, 0.5);
}

.table-hover>tbody>tr.active:hover>td {
  background-color: #1e1e1e;
}

/* src/App.css */

.thin-border {
  border: 1px solid rgba(255, 255, 255, 0.15);
  /* Thin border with light grey color */
  margin-bottom: 10px;
  margin-top: 10px;
  /* Add margin at the bottom */
  padding: 10px;
  /* Optional: Add padding inside the div */
  border-radius: 4px;
  /* Optional: Add slight rounding of the corners */
}


.info-wrap h5 {
  font-size: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 16px;
}

.info-wrap p {
  color: rgba(255, 255, 255, 0.5);
}

.list-view-pc {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 88px;
}

.list-view-pc a,
.list-view-pc a:hover {
  width: 100%;
  background: url(../images/right-arrow-icon.svg) no-repeat right;
  /* background-size: 1.16px; */
  padding: 32px 64px 32px 0px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
}

.list-view-pc a label:hover {
  pointer-events: none
}

.pc-switch-div {
  position: absolute;
  right: 64px;
  padding-left: 0px;
}

.font-24 {
  font-size: 24px;
}

.counter {
  color: #FFFFFF;
  font-size: 95px;
  min-width: 60px;
  display: inline-block;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 17px;
}

.optMargin {
  margin-top: 50px;
}

.support-content {
  font-size: 27px;
}

.onboarding-align {
  text-align: center;
}

.custom-checkbox {
  width: 15px;
  height: 15px;
  transform: scale(1.5);
  /* Increase size */
  cursor: pointer;
}

.status-cell {
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.status-cell.SUCCEEDED {
  text-align: left;
  color: green;
}

.status-cell.FAILED {
  text-align: left;
  color: red;
}

.status-cell.IN_PROGRESS {
  color: blue;
  text-align: left;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.refresh-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
}